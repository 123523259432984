<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl>
            <edit-account></edit-account>
        </v-container>
    </div>
</template>

<script>
const EditAccount = () => import('@/components/EditAccount.vue')

export default {
    data: () => ({

    }),

    components: {
        EditAccount
    },

    computed: {
    
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name)
    }
}

</script>

<style>

</style>